<!-- 培训学员考勤-学员 -->
<template>
    <div class="Pupil">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">培训学员考勤-学员</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <div class="" style="margin-left:22px ;">
            <el-button size="small" icon="el-icon-refresh" style="background-color: #2C3E50; border-radius: 3px;"
                @click="refresh">
                <!-- 刷新 -->
            </el-button>
            <el-button size="small" icon="el-icon-plus" style="background: #18BC9C;color: #FFFFFF;"
                @click="newlyAdded">
                新增
            </el-button>
        </div>
        <!-- 表格 -->
        <el-table :data="tableData" border stripe style="width:97.8%;flex: 1;" height="650" >
            <!-- 复选按钮 -->
            <el-table-column type="selection" align="center" width="50">
            </el-table-column>
            <el-table-column type="index" label="序号" align="center" width="80">
            </el-table-column>
            <el-table-column prop="sign_status" sortable label="签到状态" align="center" width="120">
                <template slot-scope="scope">
                    <span v-if="scope.row.sign_status==1">签到</span>
                    <span v-else-if="scope.row.sign_status==2">请假</span>
                    <span v-else-if="scope.row.sign_status==3">未到</span>
                    <span v-else-if="scope.row.sign_status==4">休学</span>
                </template>
            </el-table-column>
            <el-table-column prop="student_card" sortable label="学生卡号" align="center" width="150">
            </el-table-column>
            <el-table-column prop="student_name" :sortable="true" :sort-method="fn2" label="学生姓名" align="center" width="150">
            </el-table-column>
            <el-table-column prop="student_describe" sortable label="学生描述" align="center" width="300"
            :show-overflow-tooltip='true'>
            </el-table-column>
            <el-table-column prop="sign_type" sortable label="上课类" align="center" width="120">
                <template slot-scope="scope">
                    <span v-if="scope.row.sign_type==1">常规</span>
                    <span v-else-if="scope.row.sign_type==2">试学</span>
                    <span v-else-if="scope.row.sign_type==2">补课</span>
                </template>
            </el-table-column>
            <el-table-column prop="course_time" sortable label="上课时间" align="center" width="120">
            </el-table-column>
            <el-table-column prop="course_date" sortable label="上课日期" align="center" width="150">
            </el-table-column>
            <el-table-column prop="remark" sortable label="说明" align="center" width="350"
            :show-overflow-tooltip='true'>
            </el-table-column>
            <el-table-column  label="操作" align="center" fixed="right" width="200">
                <template slot-scope="scope">
                    <el-button icon="el-icon-edit" size="mini" style="background:#18BC9C;color: #fff;" 
                     @click="edit(scope.$index, scope.row)">
                        编辑
                    </el-button>
                    <el-button  icon="el-icon-delete" size="mini" style="background:#E74C3C;color: #fff;"
                     @click="del(scope.$index, scope.row)">
                        删除
                    </el-button>
                </template>
            </el-table-column>
            
        </el-table>
        <el-pagination @size-change="handleSizeChange"
         @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[20,50,100,200,500,1000]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                tableData:[],
                currentPage: 1,
                currentLimit:20,//条数
                total: 0,
            }
        },
        created() {
            this.getList()
        },
        activated() {
            this.getList()
        },
        methods:{
            goBack() { //返回
                this.$router.go(-1);
            },
            getList(){
                this.$request({
                    url:'/api/classsign/studentSignList',
                    method:'POST',
                    data:{
                        schedule_id:this.$route.query.schedule_id,
                        page:this.currentPage,
                        limit:this.currentLimit
                    }
                }).then(res=>{
                    if(res.code==1){
                        console.log(res,'列表')
                        this.tableData=res.data.list
                        this.total=res.data.count
                    }
                })
            },
            handleSizeChange(val) {
                this.currentLimit=val
                this.getList()
            },
            handleCurrentChange(val) {
                this.currentPage=val
                this.getList()
            },
            // 刷新
            refresh() {
                this.$router.push({
                    path: "/home/newpage", //空页面路由
                    query: {
                        path: this.$route.path
                    }
                })
            },
            newlyAdded() { //新增
                this.$router.push({
                    path: "addPupil",
                    query:{
                        class_id:this.$route.query.class_id
                    }
                })
            },
            edit(index,row) { //编辑
                this.$router.push({
                    path: "editPupil",
                    query:{
                        class_id:this.$route.query.class_id,
                        id:row.id
                    }
                })
            },
            del(index,row){//删除
                this.$confirm('是否删除此条记录?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$request({
                        url: '/api/classsign/studentSignDel',
                        method: 'POST',
                        data: {
                            id: row.id
                        }
                    }).then(res => {
                        if (res.code == 1) {
                            this.$message({
                                message: res.msg,
                                type: 'success'
                            });
                            this.tableData.splice(index, 1)
                            this.getList()
                        }
                    })
                })
            },
            // 姓名排序
            fn2(a, b){
                return a.student_name.localeCompare(b.student_name)
            }
        }
    }
</script>

<style scoped="scoped">
    .Pupil{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 20px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* 刷新 新增 按钮 */
    ::v-deep .el-icon-refresh {
        font-weight: bold !important;
        color: #FFFFFF;
    }
    
    ::v-deep .el-icon-plus {
        font-weight: bold !important;
    }
    /* 表格 */
    .el-table {
        margin: 20px;
    }
    
    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 30px;
    }
    
    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }
    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active{
        background-color: #2C3E50 !important;
    }
</style>
